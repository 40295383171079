var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        { staticClass: "vertical-center d-inline-flex flex-gap-2" },
        [
          _vm.user?.detail?.is_dispatcher
            ? _c("span", { staticClass: "mt-1 dot bg-secondary" }, [
                _vm._v("👔"),
              ])
            : _vm.user?.detail?.parent_id != null
            ? _c("span", { staticClass: "mt-1 dot bg-secondary" }, [
                _vm._v("👷"),
              ])
            : _c("CIcon", { attrs: { name: "cil-user" } }),
          _c(
            "h5",
            { staticClass: "mb-0" },
            [
              _vm._v(" " + _vm._s(this.$route.meta.label) + " "),
              _vm.user && !_vm.spinners.loading
                ? [
                    _vm._v(" : "),
                    _c(
                      "h3",
                      { staticClass: "d-inline" },
                      [
                        _c("CBadge", { attrs: { color: "secondary" } }, [
                          _vm._v(_vm._s(_vm.user.first_name)),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c(
                "CButton",
                { attrs: { color: "dark", to: { name: "Couriers" } } },
                [
                  _c("CIcon", {
                    staticClass: "align-bottom",
                    attrs: { name: "cil-arrow-thick-left" },
                  }),
                  _vm._v(" Back "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardBody",
        [
          !_vm.isValid
            ? _c("form-summary", {
                staticClass: "form-errors alert alert-danger",
                attrs: { validator: _vm.$v.form, attributes: _vm.attributes },
              })
            : _vm._e(),
          !_vm.spinners.loading
            ? _c(
                "CForm",
                { staticClass: "courier-form" },
                [
                  _c(
                    "CTabs",
                    {
                      staticClass: "tab-menu",
                      attrs: {
                        "active-tab": _vm.activeTab,
                        variant: "pills",
                        vertical: {
                          navs: "col-xl-2 col-md-3",
                          content: "col-xl-10 col-md-9",
                        },
                      },
                      on: {
                        "update:activeTab": function ($event) {
                          _vm.activeTab = $event
                        },
                        "update:active-tab": function ($event) {
                          _vm.activeTab = $event
                        },
                      },
                    },
                    [
                      _c(
                        "CTab",
                        { attrs: { active: "" } },
                        [
                          _c(
                            "template",
                            { slot: "title" },
                            [
                              _c("CIcon", { attrs: { name: "cil-task" } }),
                              _vm._v(" General Information "),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column" },
                            [
                              !_vm.itemId
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mb-3 d-flex flex-column flex-md-row",
                                    },
                                    [
                                      _c(
                                        "v-select",
                                        {
                                          staticClass: "w-100",
                                          attrs: {
                                            placeholder:
                                              _vm.users.length == 0
                                                ? "Search for a user.."
                                                : "Select or search..",
                                            options: _vm.users,
                                            searchable: true,
                                            loading:
                                              _vm.spinners.searching &&
                                              _vm.users.length == 0,
                                          },
                                          on: {
                                            search: _vm.searchUsers,
                                            input: _vm.handleUserInput,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "option",
                                                fn: function (user) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "c-avatar mr-2",
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "c-avatar-img",
                                                              attrs: {
                                                                src:
                                                                  user.image_icon ||
                                                                  user.gravatar,
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "small",
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  user.first_name
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(
                                                              " #" +
                                                                _vm._s(
                                                                  user.id
                                                                ) +
                                                                " (" +
                                                                _vm._s(
                                                                  user.email
                                                                ) +
                                                                ")"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "selected-option",
                                                fn: function (user) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          user.first_name
                                                        ) +
                                                        " #" +
                                                        _vm._s(user.id) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1414154059
                                          ),
                                          model: {
                                            value: _vm.user,
                                            callback: function ($$v) {
                                              _vm.user = $$v
                                            },
                                            expression: "user",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "no-options" },
                                              slot: "no-options",
                                            },
                                            [_vm._v("Type to search users..")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.user
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column flex-sm-row align-items-center justify-content-between mb-4 h-100",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-fill align-self-center",
                                          },
                                          [
                                            _c("CImg", {
                                              staticClass:
                                                "rounded-circle bg-white",
                                              staticStyle: {
                                                width: "96px",
                                                height: "96px",
                                              },
                                              attrs: {
                                                src:
                                                  _vm.user.image_icon ||
                                                  _vm.user.gravatar,
                                                placeholderColor: "lightgray",
                                              },
                                            }),
                                            _c(
                                              "h2",
                                              { staticClass: "d-inline ml-2" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.user.first_name)
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "p-2 mr-4 user-info" },
                                          [
                                            _c(
                                              "address",
                                              {
                                                staticClass: "mb-1 blockquote",
                                              },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    "#" + _vm._s(_vm.user.id)
                                                  ),
                                                ]),
                                                _c("br"),
                                                _c("CIcon", {
                                                  attrs: { name: "cil-mobile" },
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.form.detail
                                                        .phone_number ??
                                                        _vm.user.mobile
                                                    )
                                                ),
                                                _c("br"),
                                                _c("CIcon", {
                                                  attrs: { name: "cil-at" },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: `mailto:${_vm.user.email}`,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.user.email)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.user?.dispatcher
                                              ? _c(
                                                  "address",
                                                  {
                                                    staticClass:
                                                      "mb-1 blockquote",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "rounded-circle bg-secondary",
                                                      },
                                                      [_vm._v("👔")]
                                                    ),
                                                    _c("small", [
                                                      _vm._v(
                                                        " #" +
                                                          _vm._s(
                                                            _vm.user.dispatcher
                                                              .id
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.user.dispatcher
                                                              .first_name
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { staticClass: "button" },
                                              [
                                                _c(
                                                  "CLink",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name: "Edit User",
                                                        params: {
                                                          id: _vm.user.id,
                                                        },
                                                        query: { tab: 0 },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("CIcon", {
                                                      attrs: {
                                                        name: "cil-pen",
                                                      },
                                                    }),
                                                    _vm._v(" Edit User "),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-group w-100 mb-2" },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "d-block" },
                                          [_vm._v("Hubs:")]
                                        ),
                                        _c("v-select", {
                                          attrs: {
                                            placeholder: "Hubs..",
                                            label: "name",
                                            options: _vm.hubs,
                                            searchable: false,
                                            reduce: (hub) => hub.id,
                                            multiple: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "option",
                                                fn: function (hub) {
                                                  return [
                                                    _c("strong", [
                                                      _vm._v(_vm._s(hub.zip)),
                                                    ]),
                                                    _vm._v(" - "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(hub.name) + " "
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2459163088
                                          ),
                                          model: {
                                            value: _vm.form.hubs,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "hubs", $$v)
                                            },
                                            expression: "form.hubs",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column flex-md-row mb-2",
                                      },
                                      [
                                        _c("CSelect", {
                                          staticClass: "w-100 mr-2",
                                          attrs: {
                                            label: "Travel Mode:",
                                            placeholder: "Please select..",
                                            options: _vm.getTravelModes,
                                            value:
                                              _vm.$v.form.vehicle.mode.$model,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.$v.form.vehicle.mode,
                                                "$model",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _c("CInput", {
                                          staticClass: "w-100",
                                          attrs: {
                                            label: "Vehicle Plate:",
                                            placeholder: "License Plate",
                                            value:
                                              _vm.$v.form.vehicle.license_plate
                                                .$model,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.$v.form.vehicle
                                                  .license_plate,
                                                "$model",
                                                $event
                                              )
                                            },
                                            input: (val) => {
                                              if (val.trim() === "")
                                                _vm.form.vehicle.license_plate =
                                                  null
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    !_vm.user.detail?.parent_id
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column flex-md-row flex-gap-2 mb-4",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-group w-100",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "d-block",
                                                    staticStyle: {
                                                      "font-weight": "500",
                                                    },
                                                  },
                                                  [_vm._v("Is Dispatcher?")]
                                                ),
                                                _c("CSwitch", {
                                                  attrs: {
                                                    checked:
                                                      _vm.$v.form.detail
                                                        .is_dispatcher.$model,
                                                    disabled:
                                                      _vm.form.subordinates
                                                        .length > 0,
                                                    labelOn: "YES",
                                                    labelOff: "NO",
                                                    color: "success",
                                                    shape: "pill",
                                                    variant: "opposite",
                                                    size: "lg",
                                                  },
                                                  on: {
                                                    "update:checked": [
                                                      function ($event) {
                                                        return _vm.$set(
                                                          _vm.$v.form.detail
                                                            .is_dispatcher,
                                                          "$model",
                                                          $event
                                                        )
                                                      },
                                                      (value) =>
                                                        _vm.setNullIfFalse(
                                                          value,
                                                          "detail.is_dispatcher"
                                                        ),
                                                    ],
                                                  },
                                                }),
                                                _vm.form.detail.is_dispatcher
                                                  ? _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "form-text text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "* This dispatcher is managing " +
                                                            _vm._s(
                                                              _vm.form
                                                                .subordinates
                                                                .length
                                                            ) +
                                                            " couriers."
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "h5",
                                      {
                                        staticClass: "pb-1",
                                        staticStyle: {
                                          "border-bottom": "2px solid #636f83",
                                        },
                                      },
                                      [_vm._v("Personal Information")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column flex-md-row flex-gap-2 mb-2",
                                      },
                                      [
                                        _c("CSelect", {
                                          staticClass: "w-100",
                                          attrs: {
                                            label: "Country:",
                                            placeholder: "Please select..",
                                            options: _vm.authCountries,
                                            value:
                                              _vm.$v.form.detail.country_id
                                                .$model,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.$v.form.detail.country_id,
                                                "$model",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _c("CInput", {
                                          staticClass: "w-100",
                                          attrs: {
                                            label:
                                              "National Registration Number:",
                                            placeholder: "Rijksregisternummer",
                                            value:
                                              _vm.$v.form.detail.reg_number
                                                .$model,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.$v.form.detail.reg_number,
                                                "$model",
                                                $event
                                              )
                                            },
                                            input: (value) =>
                                              _vm.setNullIfEmpty(
                                                value,
                                                "detail.reg_number"
                                              ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column flex-md-row flex-gap-2 mb-2",
                                      },
                                      [
                                        _c("CInput", {
                                          staticClass: "w-100",
                                          attrs: {
                                            type: "date",
                                            label: "Birth Date:",
                                            value:
                                              _vm.$v.form.detail.birth_date
                                                .$model,
                                            max: new Date()
                                              .toISOString()
                                              .slice(0, 10),
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.$v.form.detail.birth_date,
                                                "$model",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _c("CInput", {
                                          staticClass: "w-100",
                                          attrs: {
                                            label: "Phone Number:",
                                            placeholder: "Phone number",
                                            lazy: false,
                                            value:
                                              _vm.$v.form.detail.phone_number
                                                .$model,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.$v.form.detail.phone_number,
                                                "$model",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column flex-md-row flex-gap-2 mb-2",
                                      },
                                      [
                                        _c("CInput", {
                                          staticClass: "w-100",
                                          attrs: {
                                            label:
                                              "Bank Account Number (IBAN):",
                                            placeholder:
                                              "Bank Account Number (IBAN)",
                                            lazy: false,
                                            value:
                                              _vm.$v.form.detail.iban.$model,
                                            isValid:
                                              _vm.checkIfValid("detail.iban"),
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.$v.form.detail.iban,
                                                "$model",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _c("CInput", {
                                          staticClass: "w-100",
                                          attrs: {
                                            label: "Bank Account Holder:",
                                            placeholder: "Account Holder Name",
                                            value:
                                              _vm.$v.form.detail
                                                .account_holder_name.$model,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.$v.form.detail
                                                  .account_holder_name,
                                                "$model",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "section",
                                      { staticClass: "address" },
                                      [
                                        _c("CInput", {
                                          staticClass: "w-100",
                                          attrs: {
                                            id: "verify_address",
                                            value: _vm.verified_address,
                                            label:
                                              "Verify Address From Google:",
                                            placeholder: "Enter a location",
                                            lazy: false,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              _vm.verified_address = $event
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "prepend",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "CButton",
                                                      {
                                                        attrs: {
                                                          color: "info",
                                                        },
                                                      },
                                                      [
                                                        _c("CIcon", {
                                                          attrs: {
                                                            name: "cil-location-pin",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            2480037663
                                          ),
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column flex-md-row",
                                          },
                                          [
                                            _c("CTextarea", {
                                              staticClass: "w-100",
                                              attrs: {
                                                rows: "2",
                                                label: "Address:",
                                                placeholder:
                                                  "Street name and house number",
                                                lazy: false,
                                                value:
                                                  _vm.$v.form.detail.address
                                                    .$model,
                                              },
                                              on: {
                                                "update:value": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.$v.form.detail.address,
                                                    "$model",
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column flex-md-row flex-gap-2",
                                          },
                                          [
                                            _c("CInput", {
                                              staticClass: "w-100",
                                              attrs: {
                                                label: "Postal Code:",
                                                placeholder: "Postal code",
                                                value:
                                                  _vm.$v.form.detail.postal_code
                                                    .$model,
                                              },
                                              on: {
                                                "update:value": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.$v.form.detail
                                                      .postal_code,
                                                    "$model",
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                            _c("CInput", {
                                              staticClass: "w-100",
                                              attrs: {
                                                label: "City:",
                                                placeholder: "City",
                                                value:
                                                  _vm.$v.form.detail.city
                                                    .$model,
                                              },
                                              on: {
                                                "update:value": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.$v.form.detail.city,
                                                    "$model",
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        2
                      ),
                      _vm.isEdit
                        ? _c(
                            "CTab",
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("CIcon", { attrs: { name: "cil-image" } }),
                                  _vm._v(" ID card "),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column col-xl-10 col-md-9",
                                },
                                [
                                  _vm.user
                                    ? [
                                        _c("h3", [_vm._v("ID Card")]),
                                        _c("cite", [
                                          _vm._v(
                                            "ID card images are used solely to validate the registered courier's identity."
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "mt-3 articles" },
                                          [
                                            _c("div", [
                                              _c(
                                                "article",
                                                { staticClass: "mb-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "article-wrapper",
                                                    },
                                                    [
                                                      _c(
                                                        "figure",
                                                        [
                                                          _c("input", {
                                                            ref: "identityFrontRef",
                                                            staticStyle: {
                                                              display: "none",
                                                            },
                                                            attrs: {
                                                              type: "file",
                                                              accept:
                                                                ".png, .jpg, .jpeg, .webp",
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.uploadFront,
                                                            },
                                                          }),
                                                          _vm.identityFront
                                                            ? _c(
                                                                "expandable-image",
                                                                {
                                                                  attrs: {
                                                                    src: _vm.identityFront,
                                                                    alt: "",
                                                                  },
                                                                }
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-column align justify-content-center align-items-center p-3",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$refs.identityFrontRef.click()
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("CIcon", {
                                                                    attrs: {
                                                                      name: "cil-plus",
                                                                      size: "xl",
                                                                    },
                                                                  }),
                                                                  _c(
                                                                    "cite",
                                                                    {
                                                                      staticClass:
                                                                        "mt-2 font-weight-light",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "No image is uploaded, please provide the front of ID card"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "article-body",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-gap-1 mb-2 justify-content-between",
                                                            },
                                                            [
                                                              _c(
                                                                "CBadge",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "info",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Front"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("div", [
                                                                _vm.identityFront
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-primary mr-2",
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$refs.identityFrontRef.click()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "CIcon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                name: "cil-pencil",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.identityFront &&
                                                                _vm.isSuperAdmin &&
                                                                _vm.canDeleteIdentityFront
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-danger",
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeIDCard(
                                                                                "FRONT"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "CIcon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                name: "cil-trash",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm.identityFrontError
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors
                                                            .identityFront
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("div", [
                                              _c(
                                                "article",
                                                { staticClass: "mb-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "article-wrapper",
                                                    },
                                                    [
                                                      _c(
                                                        "figure",
                                                        [
                                                          _c("input", {
                                                            ref: "identityBackRef",
                                                            staticStyle: {
                                                              display: "none",
                                                            },
                                                            attrs: {
                                                              type: "file",
                                                              accept:
                                                                ".png, .jpg, .jpeg, .webp",
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.uploadBack,
                                                            },
                                                          }),
                                                          _vm.identityBack
                                                            ? _c(
                                                                "expandable-image",
                                                                {
                                                                  attrs: {
                                                                    src: _vm.identityBack,
                                                                    alt: "",
                                                                  },
                                                                }
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-column align justify-content-center align-items-center p-3",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$refs.identityBackRef.click()
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("CIcon", {
                                                                    attrs: {
                                                                      name: "cil-plus",
                                                                      size: "xl",
                                                                    },
                                                                  }),
                                                                  _c(
                                                                    "cite",
                                                                    {
                                                                      staticClass:
                                                                        "mt-2 font-weight-light",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "No image is uploaded, please provide the back of ID card"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "article-body",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-gap-1 mb-2 justify-content-between",
                                                            },
                                                            [
                                                              _c(
                                                                "CBadge",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "info",
                                                                  },
                                                                },
                                                                [_vm._v("Back")]
                                                              ),
                                                              _c("div", [
                                                                _vm.identityBack
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-primary mr-2",
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$refs.identityBackRef.click()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "CIcon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                name: "cil-pencil",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.identityBack &&
                                                                _vm.isSuperAdmin &&
                                                                _vm.canDeleteIdentityBack
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-danger",
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeIDCard(
                                                                                "BACK"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "CIcon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                name: "cil-trash",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm.identityBackError
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors
                                                            .identityBack
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      _c(
                        "CTab",
                        { attrs: { disabled: !_vm.form.user_id } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "div",
                              [
                                _c("CIcon", { attrs: { name: "cil-pen-nib" } }),
                                _vm._v(" Contract"),
                              ],
                              1
                            ),
                          ]),
                          _c("h3", [_vm._v("Contract")]),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column" },
                            [
                              _c(
                                "h5",
                                {
                                  staticClass: "pb-1 mt-1 mb-3",
                                  staticStyle: {
                                    "border-bottom": "2px solid #636f83",
                                  },
                                },
                                [_vm._v("Business Information")]
                              ),
                              _vm.hasActiveContract
                                ? _c(
                                    "div",
                                    { staticClass: "alert alert-info p-2" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vertical-center flex-gap-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "h1 mb-0" },
                                            [_vm._v("🛈")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "flex-grow-1 lead" },
                                            [
                                              _vm._v(
                                                "Contract start date: " +
                                                  _vm._s(
                                                    _vm.form.active_contract
                                                      .start_date
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c("CInputCheckbox", {
                                            attrs: {
                                              custom: "",
                                              label: "Edit",
                                              checked: _vm.editContract,
                                            },
                                            on: {
                                              "update:checked": function (
                                                $event
                                              ) {
                                                _vm.editContract = $event
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  class: {
                                    "disable-item":
                                      _vm.hasActiveContract &&
                                      !_vm.editContract,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column flex-md-row flex-gap-2 mb-2",
                                    },
                                    [
                                      _c("CSelect", {
                                        staticClass: "w-100",
                                        attrs: {
                                          label: "Contract Model:",
                                          placeholder: "Please select..",
                                          options: [
                                            {
                                              value: "per_order",
                                              label: "Per order",
                                            },
                                            {
                                              value: "hourly",
                                              label: "Hourly",
                                            },
                                          ],
                                          value:
                                            _vm.$v.form.active_contract
                                              .contract_model.$model,
                                        },
                                        on: {
                                          "update:value": function ($event) {
                                            return _vm.$set(
                                              _vm.$v.form.active_contract
                                                .contract_model,
                                              "$model",
                                              $event
                                            )
                                          },
                                          change: () =>
                                            (_vm.form.active_contract.employment_type =
                                              null),
                                        },
                                      }),
                                      _c("CSelect", {
                                        staticClass: "w-100",
                                        attrs: {
                                          label: "Employment Type:",
                                          placeholder: "Please select..",
                                          options: _vm.employmentTypeOptions,
                                          value:
                                            _vm.$v.form.active_contract
                                              .employment_type.$model,
                                        },
                                        on: {
                                          "update:value": function ($event) {
                                            return _vm.$set(
                                              _vm.$v.form.active_contract
                                                .employment_type,
                                              "$model",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.vatRequired
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column flex-md-row flex-gap-2 mb-2",
                                        },
                                        [
                                          _c("CInput", {
                                            staticClass: "w-100",
                                            attrs: {
                                              label: "VAT (BTW) Number:",
                                              placeholder: "VAT number, if any",
                                              value:
                                                _vm.$v.form.active_contract
                                                  .vat_number.$model,
                                              isValid: _vm.checkIfValid(
                                                "active_contract.vat_number"
                                              ),
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.$v.form.active_contract
                                                    .vat_number,
                                                  "$model",
                                                  $event
                                                )
                                              },
                                              focusout: _vm.fixVat,
                                              input: (value) =>
                                                _vm.setNullIfEmpty(
                                                  value,
                                                  "active_contract.vat_number"
                                                ),
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "CButton",
                                                        {
                                                          attrs: {
                                                            color: "info",
                                                            disabled:
                                                              _vm.spinners
                                                                .btnCompanyInfo ||
                                                              !_vm.form
                                                                .active_contract
                                                                .vat_number ||
                                                              !_vm.isValidVat(
                                                                _vm.form
                                                                  .active_contract
                                                                  .vat_number
                                                              ),
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.getCompanyInfo,
                                                          },
                                                        },
                                                        [
                                                          _vm.spinners
                                                            .btnCompanyInfo
                                                            ? _c("CSpinner", {
                                                                attrs: {
                                                                  size: "sm",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(
                                                            " Get Company Info "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              1484638489
                                            ),
                                          }),
                                          _c("CInput", {
                                            staticClass: "w-100",
                                            attrs: {
                                              label: "Company Name:",
                                              placeholder: "Company name",
                                              value:
                                                _vm.$v.form.active_contract
                                                  .company_name.$model,
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.$v.form.active_contract
                                                    .company_name,
                                                  "$model",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column flex-md-row flex-gap-2 mb-2",
                                    },
                                    [
                                      _c("CInput", {
                                        staticClass: "w-100",
                                        attrs: {
                                          label: `Rate for '${_vm.form.active_contract?.contract_model?.replace(
                                            "_",
                                            " "
                                          )}' contract model:`,
                                          placeholder: "0.00",
                                          value:
                                            _vm.$v.form.active_contract.rate
                                              .$model,
                                          type: "number",
                                          step: "0.01",
                                          pattern: "^\\d+(?:\\.\\d{1,2})?$",
                                        },
                                        on: {
                                          "update:value": function ($event) {
                                            return _vm.$set(
                                              _vm.$v.form.active_contract.rate,
                                              "$model",
                                              $event
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "prepend-content",
                                              fn: function () {
                                                return [
                                                  _c("CIcon", {
                                                    attrs: { name: "cil-euro" },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          586003441
                                        ),
                                      }),
                                      _c("div", { staticClass: "w-100" }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "h5",
                                {
                                  staticClass: "pb-1 my-3",
                                  staticStyle: {
                                    "border-bottom": "2px solid #636f83",
                                  },
                                },
                                [_vm._v("Contract History")]
                              ),
                              _c("ContractHistory", {
                                attrs: {
                                  courierId: _vm.form.user_id?.toString(),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "CTab",
                        { attrs: { disabled: !_vm.form.user_id } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "div",
                              [
                                _c("CIcon", {
                                  attrs: { name: "cil-lock-unlocked" },
                                }),
                                _vm._v(" Permissions"),
                              ],
                              1
                            ),
                          ]),
                          _c("h3", [_vm._v("Permissions")]),
                          _c("courier-permissions", {
                            attrs: { permissions: _vm.form.detail.permissions },
                            on: {
                              "update:permissions": function ($event) {
                                return _vm.$set(
                                  _vm.form.detail,
                                  "permissions",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "CTab",
                        {
                          attrs: {
                            disabled:
                              !_vm.form.user_id || _vm.hasFlexibleWorkingHours,
                          },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "div",
                              [
                                _c("CIcon", { attrs: { name: "cil-clock" } }),
                                _vm._v(" Working Hours"),
                              ],
                              1
                            ),
                          ]),
                          _c("h3", [_vm._v("Working Hours")]),
                          _c("working-hours", {
                            attrs: { workingHours: _vm.workingHours },
                            on: {
                              "update:workingHours": function ($event) {
                                _vm.workingHours = $event
                              },
                              "update:working-hours": function ($event) {
                                _vm.workingHours = $event
                              },
                              validate: (invalid) =>
                                (_vm.workingHoursInvalid = invalid),
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "CTab",
                        {
                          attrs: {
                            disabled:
                              !_vm.form.user_id || _vm.hasFlexibleWorkingHours,
                          },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "div",
                              [
                                _c("CIcon", {
                                  attrs: { name: "cil-mood-good" },
                                }),
                                _vm._v(" Custom Working Hours"),
                              ],
                              1
                            ),
                          ]),
                          _c("h3", [_vm._v("Custom Working Hours")]),
                          _c("custom-working-hours", {
                            attrs: {
                              customWorkingHours: _vm.customWorkingHours,
                            },
                            on: {
                              "update:customWorkingHours": function ($event) {
                                _vm.customWorkingHours = $event
                              },
                              "update:custom-working-hours": function ($event) {
                                _vm.customWorkingHours = $event
                              },
                              validate: (invalid) =>
                                (_vm.customWorkingHoursInvalid = invalid),
                            },
                          }),
                        ],
                        2
                      ),
                      _vm.form.detail.is_dispatcher
                        ? _c(
                            "CTab",
                            [
                              _c("template", { slot: "title" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "dot bg-secondary" },
                                    [_vm._v("👷")]
                                  ),
                                  _vm._v(" Subordinates"),
                                ]),
                              ]),
                              _c("h3", [_vm._v("Subordinate Couriers")]),
                              _c(
                                "CLink",
                                {
                                  staticClass: "mb-3 d-block",
                                  on: {
                                    click: function ($event) {
                                      _vm.addSubordinatesModalShow = true
                                    },
                                  },
                                },
                                [
                                  _c("CIcon", { attrs: { name: "cil-plus" } }),
                                  _vm._v(" Add couriers as subordinates "),
                                ],
                                1
                              ),
                              _c("CourierList", {
                                attrs: { couriers: _vm.form.subordinates },
                                on: {
                                  "update:couriers": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "subordinates",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                      _c(
                        "CTab",
                        [
                          _c(
                            "template",
                            { slot: "title" },
                            [
                              _c("CIcon", { attrs: { name: "cil-notes" } }),
                              _vm._v(" Registration Notes "),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column col-xl-10 col-md-9",
                            },
                            [
                              _vm.user
                                ? [
                                    _c("h3", [_vm._v("Registration Notes")]),
                                    _vm.validRegNotes
                                      ? _c("cite", [
                                          _vm._v(
                                            "A courier registration has been received via our website. Please review and process the provided information to activate the courier's account."
                                          ),
                                        ])
                                      : _c("cite", [
                                          _vm._v(
                                            "There are no registration notes available for this courier."
                                          ),
                                        ]),
                                    _c("div", { staticClass: "mt-3" }, [
                                      _vm.validRegNotes
                                        ? _c("ul", [
                                            _vm.user.detail?.registration_notes
                                              .selectedCity != null
                                              ? _c("li", [
                                                  _vm._v("Wants to work in: "),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.user.detail?.registration_notes.selectedCity?.capitalize()
                                                      )
                                                    ),
                                                  ]),
                                                ])
                                              : _vm._e(),
                                            _vm.user.detail?.registration_notes
                                              .isFlexHours != null
                                              ? _c("li", [
                                                  _vm._v("Contract model: "),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.user.detail
                                                          ?.registration_notes
                                                          .isFlexHours
                                                          ? "Per Order"
                                                          : "Hourly"
                                                      )
                                                    ),
                                                  ]),
                                                ])
                                              : _vm._e(),
                                            this.user.detail?.registration_notes
                                              .selectedShift != null
                                              ? _c("li", [
                                                  _vm._v("Working "),
                                                  _c("strong", [
                                                    _vm._v(_vm._s(_vm.shift)),
                                                  ]),
                                                ])
                                              : _vm._e(),
                                            _vm.user.detail?.registration_notes
                                              .selectedVehicle != null
                                              ? _c("li", [
                                                  _vm._v("Want's to use "),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm
                                                          .getTravelModeByType(
                                                            _vm.user.detail
                                                              ?.registration_notes
                                                              .selectedVehicle
                                                          )
                                                          .label.toLowerCase()
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.user.detail
                                                          ?.registration_notes
                                                          .licensePlate
                                                          ? "with license plate: "
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                  _vm.user.detail
                                                    ?.registration_notes
                                                    .licensePlate
                                                    ? _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.user.detail
                                                              ?.registration_notes
                                                              .licensePlate
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-content-center mt-5",
                                            },
                                            [
                                              _c("CIcon", {
                                                staticClass: "warning-icon",
                                                attrs: { name: "cil-warning" },
                                              }),
                                            ],
                                            1
                                          ),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("CSpinner", { attrs: { color: "primary" } }),
        ],
        1
      ),
      _c("CCardFooter", { staticClass: "sticky-bottom" }, [
        _c(
          "div",
          {
            staticClass: "d-flex flex-wrap align-items-center",
            staticStyle: { gap: "0.75rem" },
          },
          [
            _c(
              "CButton",
              {
                attrs: {
                  color: "primary",
                  disabled:
                    _vm.spinners.loading ||
                    _vm.spinners.btnSubmit ||
                    !_vm.form.user_id,
                },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [
                _vm.spinners.btnSubmit
                  ? _c("CSpinner", { attrs: { size: "sm" } })
                  : _vm._e(),
                _vm._v(" Submit "),
              ],
              1
            ),
            _c(
              "CButton",
              {
                attrs: { color: "success", disabled: !_vm.showUploadBtn },
                on: {
                  click: function ($event) {
                    return _vm.uploadIdentity()
                  },
                },
              },
              [
                _vm.spinners.btnUpload
                  ? _c("CSpinner", { attrs: { size: "sm" } })
                  : _vm._e(),
                _vm._v(" Upload ID Card "),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "CModal",
        {
          staticClass: "add-couriers-modal",
          attrs: {
            title: "Add Couriers",
            color: "white",
            size: "lg",
            show: _vm.addSubordinatesModalShow,
            closeOnBackdrop: false,
          },
          on: {
            "update:show": function ($event) {
              _vm.addSubordinatesModalShow = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "link" },
                      on: {
                        click: function ($event) {
                          _vm.addSubordinatesModalShow = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: {
                        color: "success",
                        disabled: !_vm.newSubordinates.length,
                      },
                      on: { click: _vm.addSubordinates },
                    },
                    [
                      _c("CIcon", {
                        staticClass: "mt-0",
                        attrs: { name: "cil-plus" },
                      }),
                      _vm._v(" Add "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.user
            ? _c("add-couriers", {
                attrs: {
                  active: _vm.addSubordinatesModalShow,
                  "courier-ids": [
                    _vm.user?.id,
                    ..._vm.form?.subordinates?.pluck("id"),
                  ].join(","),
                  "selected-items": _vm.newSubordinates,
                  options: {
                    excludeSubordinates: true,
                    excludeDispatchers: true,
                  },
                },
                on: {
                  "update:selectedItems": function ($event) {
                    _vm.newSubordinates = $event
                  },
                  "update:selected-items": function ($event) {
                    _vm.newSubordinates = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("mc-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.spinners.btnSubmit || _vm.spinners.btnUpload,
            expression: "spinners.btnSubmit || spinners.btnUpload",
          },
        ],
        attrs: { opacity: 0.8 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }