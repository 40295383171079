var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CDataTable", {
    staticClass: "table-couriers",
    attrs: {
      striped: "",
      hover: "",
      items: _vm.courierList,
      fields: _vm.fields,
      noItemsView: {
        noResults: "No filtering results are available!",
        noItems: "No couriers found!",
      },
      "clickable-rows": "",
    },
    on: { "row-clicked": _vm.rowClicked },
    scopedSlots: _vm._u([
      {
        key: "id",
        fn: function ({ item }) {
          return [_c("td", [_vm._v(" #" + _vm._s(item?.id) + " ")])]
        },
      },
      {
        key: "image_icon",
        fn: function ({ item }) {
          return [
            _c(
              "td",
              [
                _c("CImg", {
                  staticClass: "c-avatar-img",
                  staticStyle: { width: "36px", height: "36px" },
                  attrs: {
                    src: item.image_icon || item.gravatar,
                    placeholderColor: "lightgray",
                  },
                }),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "name",
        fn: function ({ item }) {
          return [_c("td", [_vm._v(" " + _vm._s(item.first_name) + " ")])]
        },
      },
      {
        key: "contact",
        fn: function ({ item }) {
          return [
            _c("td", [
              _c(
                "div",
                [
                  _c("CIcon", {
                    staticClass: "mr-1",
                    attrs: { name: "cil-at" },
                  }),
                  _vm._v(_vm._s(item.email)),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("CIcon", {
                    staticClass: "mr-1",
                    attrs: { name: "cil-phone" },
                  }),
                  _vm._v(_vm._s(item.mobile)),
                ],
                1
              ),
            ]),
          ]
        },
      },
      {
        key: "vehicle",
        fn: function ({ item }) {
          return [
            _c("td", [
              item.vehicle
                ? _c(
                    "div",
                    { staticClass: "d-flex align-items-center flex-gap-2" },
                    [
                      _c("MIcon", { attrs: { tag: item.vehicle.mode } }),
                      _c("strong", { staticClass: "text-uppercase" }, [
                        _vm._v(_vm._s(item.vehicle.license_plate)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        },
      },
      {
        key: "created_at",
        fn: function ({ item }) {
          return [
            _c("td", [
              _vm._v(_vm._s(_vm.moment(item.pivot?.created_at).format("LL"))),
            ]),
          ]
        },
      },
      {
        key: "actions",
        fn: function ({ item, index }) {
          return [
            _c(
              "td",
              [
                _c(
                  "CButton",
                  {
                    attrs: {
                      size: "sm",
                      color: "danger",
                      variant: "ghost",
                      shape: "pill",
                      disabled: item.is_deletable !== true,
                    },
                    on: {
                      click: function ($event) {
                        item.is_deletable === true &&
                          _vm.$delete(_vm.courierList, index)
                      },
                    },
                  },
                  [_c("CIcon", { attrs: { name: "cil-trash" } })],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }