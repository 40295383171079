var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "CButton",
        {
          attrs: {
            color: "info",
            variant: "ghost",
            shape: "pill",
            size: "sm",
            disabled: _vm.loading,
          },
          on: { click: _vm.getContracts },
        },
        [
          _c(
            "div",
            { staticClass: "vertical-center flex-gap-2" },
            [
              _vm.loading
                ? _c("CSpinner", { attrs: { size: "sm" } })
                : _c("CIcon", { attrs: { name: "cil-history" } }),
              _vm._v(" Get Contracts "),
            ],
            1
          ),
        ]
      ),
      _vm.contracts.length
        ? _c("CDataTable", {
            staticClass: "table-couriers",
            attrs: {
              striped: "",
              hover: "",
              items: _vm.contracts,
              fields: _vm.fields,
              noItemsView: {
                noResults: "No filtering results are available!",
                noItems: "No contracts found!",
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "contract_model",
                  fn: function ({ item }) {
                    return [
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("uppercase")(
                                _vm._f("normalizeSentence")(item.contract_model)
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "employment_type",
                  fn: function ({ item }) {
                    return [
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("uppercase")(
                                _vm._f("normalizeSentence")(
                                  item.employment_type
                                )
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "rate",
                  fn: function ({ item }) {
                    return [
                      _c("td", [
                        _vm._v(
                          " " + _vm._s(_vm._f("toCurrency")(item.rate)) + " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "start_date",
                  fn: function ({ item }) {
                    return [
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.moment(item.start_date).format("LL"))
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "end_date",
                  fn: function ({ item }) {
                    return [
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            item.end_date
                              ? _vm.moment(item.end_date).format("LL")
                              : "…"
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              2951978122
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }