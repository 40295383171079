var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "expandable-image" }, [
    _c("i", { staticClass: "expand-button" }, [
      _c(
        "svg",
        {
          staticStyle: { width: "24px", height: "24px" },
          attrs: { viewBox: "0 0 24 24" },
        },
        [
          _c("path", {
            attrs: {
              fill: "#000000",
              d: "M10,21V19H6.41L10.91,14.5L9.5,13.09L5,17.59V14H3V21H10M14.5,10.91L19,6.41V10H21V3H14V5H17.59L13.09,9.5L14.5,10.91Z",
            },
          }),
        ]
      ),
    ]),
    !_vm.expanded
      ? _c(
          "img",
          _vm._b(
            {
              staticClass: "inline-image",
              on: {
                click: function ($event) {
                  _vm.expanded = true
                },
              },
            },
            "img",
            _vm.$attrs,
            false
          )
        )
      : _vm._e(),
    _vm.expanded
      ? _c(
          "div",
          {
            staticClass: "expanded-overlay",
            on: {
              click: _vm.closeExpanded,
              mousemove: _vm.onMouseMove,
              touchmove: _vm.onTouchMove,
            },
          },
          [
            _c(
              "button",
              {
                staticClass: "zoom-button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toggleZoom.apply(null, arguments)
                  },
                },
              },
              [
                _vm.isZoomed
                  ? _c("CIcon", { attrs: { name: "cil-zoom-out" } })
                  : _c("CIcon", { attrs: { name: "cil-zoom-in" } }),
              ],
              1
            ),
            _c(
              "button",
              { staticClass: "close-button", on: { click: _vm.closeExpanded } },
              [_c("CIcon", { attrs: { name: "cil-x" } })],
              1
            ),
            _c("div", { staticClass: "image-container" }, [
              _c(
                "img",
                _vm._b(
                  {
                    ref: "zoomableImage",
                    style: _vm.imageStyle,
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.toggleZoom.apply(null, arguments)
                      },
                    },
                  },
                  "img",
                  _vm.$attrs,
                  false
                )
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }